<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title" :visible.sync="isVisible" :before-close="handleClose" :detailId="detailId">
    <el-form ref="form" :model="courseData" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="所属分类" prop="category_id">
            <el-cascader
              v-model="pidValue"
              :options="pidOptions"
              :props="{multiple: true}"
              @change="handleChange">
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="封面图" prop="img_url">
            <el-upload
              ref="upload"
              :action="uploadImage.action"
              :headers="uploadImage.headers"
              :file-list="uploadImage.fileList"
              :on-success="handleUpload"
              list-type="picture-card"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上架" prop="money">
            <el-radio v-model="courseData.is_on" label="1">是</el-radio>
            <el-radio v-model="courseData.is_on" label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="courseData.title" placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="12">
            <el-form-item label="课时" prop="class_hour_nums">
              <el-input v-model="courseData.class_hour_nums" placeholder="课时"></el-input>
            </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="浏览次数" prop="browse_num">
            <el-input-number v-model="courseData.browse_num" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收费" prop="money">
            <el-radio v-model="courseData.money" label="1">是</el-radio>
            <el-radio v-model="courseData.money" label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort_num">
            <el-input-number v-model="courseData.sort_num" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态" prop="status">
            <el-radio v-model="courseData.status" label="1">录制完成</el-radio>
            <el-radio v-model="courseData.status" label="0">录制中</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="简介" prop="profile">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="简介"
              v-model="courseData.profile">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="答疑" prop="answer_question">
            <el-input v-model="courseData.answer_question" placeholder="答疑"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下载地址" prop="download_addr">
            <el-input v-model="courseData.download_addr" placeholder="下载地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo标题" prop="seo_title">
            <el-input v-model="courseData.seo_title"  placeholder="seo标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo关键字" prop="seo_keywords">
            <el-input v-model="courseData.seo_keywords"  placeholder="关键字"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo描述" prop="seo_description">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="seo描述"
              v-model="courseData.seo_description">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="height: 300px;">
        <el-col :span="24">
          <el-form-item label="课程介绍" prop="introduction">
            <quill-editor @EditorContent="handleContent" :Content="courseData.introduction"></quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import quillEditor from "@/components/Editor";
import tool from "@/utils/utils";
export default {
   name: "CategoryAddUpdate",
  data(){
      return {
        courseData:{
          id:1,
          category_ids:[],
          level:"",
          title:"",
          img_url:"",
          class_hour_nums:"",
          money:"0",
          browse_num:0,
          profile:"",
          sort_num:0,
          status:"0",
          is_on:"0",
          answer_question:"",
          download_addr:"",
          introduction:"",
          seo_title:"",
          seo_description:"",
          seo_keywords:"",
        },
        uploadImage:{
          action:this.$http.defaults.baseURL+"/upload_file/index",
          dialogImageUrl: '',
          dialogVisible: false,
          headers:{
            Authorization:sessionStorage.getItem("token")
          },
          fileList:[],
        },
        rules:{
          category_ids:[
            { required: true, message: '请选择分类', trigger: 'blur' },
          ],
          title:[
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          class_hour_nums:[
            { required: true, message: '请输入课时', trigger: 'blur' },
          ],
          img_url:[
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
          profile:[
            { required: true, message: '请输入简介', trigger: 'blur' },
          ],
          answer_question:[
            { required: true, message: '请输入答疑', trigger: 'blur' },
          ],
          download_addr:[
            { required: true, message: '请输入下载地址', trigger: 'blur' },
          ],
          seo_title:[
            { required: true, message: '请输入seo标题', trigger: 'blur' },
          ],
          seo_description:[
            { required: true, message: '请输入seo描述', trigger: 'blur' },
          ],
          seo_keywords:[
            { required: true, message: '请输入seo关键字', trigger: 'blur' },
          ],
          introduction:[
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
        },
        //分页数据
        pageInfo:{
          //当前页
          currentPage:1,
          //每页显示条数
          pageSize:-1,
        },
        pidValue:[],
        pidOptions:[
          {
            value: 0,
            label: "顶级",
            children:[],
          }
        ],
      }
  },
  //定义接受父组件传来的数据
  props:{
    isVisible:Boolean,
    title:String,
    detailId:Number,
  },
   methods:{
     query(){
       this.$http({
         method: 'post',
         url: '/category/custom_list',
         data: {
           page:this.pageInfo.currentPage,
           size:this.pageInfo.pageSize,
         },
       }).then(res=>{
         if (res.data.code==0) {
           let handleData = tool.getTreeData(res.data.data.list)
           console.log(handleData)
           this.pidOptions[0].children = handleData
         }else{
           this.$message.error(res.data.msg)
         }
       }).catch(err=>{
         console.log(err)
       })
     },
     //关闭之前的处理方法,向父组件传一个ChangVisible事件，false值,从而隐藏弹框
     handleClose(){
       this.$emit("ChangeVisible",false)
     },
     //根据id来判断是添加还是更新
     handleSubmit(){
       if (this.detailId!=0){ //更新
         this.$refs.form.validate((val)=>{
           if (!val){
             this.$message.error("请输入必填项")
             return false
           }
           let postData = this.courseData
           postData.is_on = Number(postData.is_on)
           postData.class_hour_nums = Number(postData.class_hour_nums)
           postData.money = Number(postData.money)
           postData.status = Number(postData.status)
           this.$http({
             method: 'post',
             url: '/course/update',
             data: postData,
           }).then(res=>{
             if (res.data.code==0) {
               //清空表单
               this.$refs.form.resetFields()
               this.courseData.introduction = ""
               this.$refs.upload.clearFiles()
               this.pidValue = []
               this.pidOptions = [
                 {
                   value: 0,
                   label: "顶级",
                   children:[],
                 }
               ]
               //关闭弹窗
               this.handleClose()
               //消息提示
               this.$message.success("操作成功")
               //子传父，调用父组件的query方法，重新加载数据
               this.$emit("query")
             }else{
               this.$message.error(res.data.msg)
             }
           }).catch(err=>{
             console.log(err)
           })
         })
       }else{ //添加
         this.$refs.form.validate((val)=>{
           if (!val){
             this.$message.error("请输入必填项")
             return false
           }
           let postData = this.courseData
           postData.is_on = Number(postData.is_on)
           postData.class_hour_nums = Number(postData.class_hour_nums)
           postData.money = Number(postData.money)
           postData.status = Number(postData.status)
           this.$http({
             method: 'post',
             url: '/course/add',
             data: postData,
           }).then(res=>{
             if (res.data.code==0) {
               //清空表单
               this.$refs.form.resetFields()
               this.courseData.introduction = ""
               this.$refs.upload.clearFiles()
               this.pidValue = []
               this.pidOptions = [
                 {
                   value: 0,
                   label: "顶级",
                   children:[],
                 }
               ]
               //关闭弹窗
               this.handleClose()
               //消息提示
               this.$message.success("操作成功")
               //子传父，调用父组件的query方法，重新加载数据
               this.$emit("query")
             }else{
               this.$message.error(res.data.msg)
             }
           }).catch(err=>{
             console.log(err)
           })

         })
       }
     },
     //分类选择
     handleChange(val){
       let ids = new Array()
       for(let i=0;i<val.length;i++){
          ids.push(val[i][val[i].length-1])
       }
       this.courseData.category_ids = ids
       this.courseData.level = JSON.stringify(val)
     },
     handleContent(val){
       this.courseData.introduction = val
     },
     handleUpload(val){
       if (val.code==0){
         this.courseData.img_url = val.data.url
       }
     },
     //清空表单数据
     handleFormData(){
       this.$refs.form.resetFields()
       this.pidValue = []
       this.uploadImage.fileList = []
       this.query()
     },
   },
  mounted() {
    this.query()
  },
  components:{
    quillEditor
  },
  watch:{
    detailId(val){
      if (val==0){
        return
      }
      //重新拉取一次分类列表
      if (this.pidOptions.length<2){
        this.query()
      }
      this.$http({
        method: 'post',
        url: '/course/detail',
        data: {
          id: val
        },
      }).then(res=>{
        if (res.data.code==0) {
          //处理category_ids
           let regObj = new RegExp(",")
           let category_ids = res.data.data.category_ids.split(regObj).map(item=>Number(item))
           //处理level
          let level = JSON.parse(res.data.data.level)
          console.log("level",level)
          this.pidValue = level
          this.uploadImage.fileList = []
          //返回值部分处理后填回courseData
          for (let x in res.data.data) {
            if (x=="category_ids"){
              this.courseData[x] = category_ids
              continue
            }
            if (x=="update_time"||x=="create_time"){
              continue
            }
            if (x=="is_on"||x=="status"||x=="money"){
              this.courseData[x] = res.data.data[x].toString()
              continue
            }
            if (x=="img_url"){
              let baseUrl =  this.$siteUrl
              let imgUrl = baseUrl+res.data.data[x].substring(1)
              console.log("imgUrl",imgUrl)
              this.uploadImage.fileList.push({url:imgUrl})
            }
            this.courseData[x] = res.data.data[x]
          }

        }else {
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
